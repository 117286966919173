import CourseHomeAccordion from "./CourseHomeAccordian";

const HomeTab = () => {
  return (
    <>
      <CourseHomeAccordion  />
    </>
  );
};

export default HomeTab;
